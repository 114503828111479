'use client';

import styles                         from 'foundations/layout/header-nav.module.css';
import Link                           from 'next/link';
import {Icon}                         from '@glimpse/glass';
import React, {useCallback, useState} from 'react';
import {useTranslation}               from 'foundations/i18n/i18n';
import classNames                     from 'classnames';
import {Button}                       from 'foundations/button/button';

export function HeaderNav() {
    const {t}             = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const toggle          = useCallback(() => {
        setOpen(!open);
    }, [open]);

    return (
        <>
            <nav className={classNames(styles.nav, {
                [styles.open]: open
            })}>
                <ul className={styles.list} onClick={toggle}>
                    <Item label={t('Features')} href={'/features'}/>
                    <Item label={t('Pricing')} href={'/pricing'}/>
                    <Item label={t('Company')} href={'/about'}/>
                    <div className={'mt-2 lg:hidden'}>
                        <Button href={'https://app.withglimpse.com'}>
                            {t('Sign In')}
                        </Button>
                    </div>
                </ul>
            </nav>
            <a className={'lg:hidden ml-auto cursor-pointer'} onClick={toggle}>
                {
                    open
                        ? <Icon fa={['far', 'fa-xmark']} fontSize={4}/>
                        : <Icon fa={['far', 'fa-bars']} fontSize={4}/>
                }
            </a>
        </>
    );
}

const Item = ({
    href,
    label
}: {
    href: string,
    label: string,
}) => {
    return (
        <>
            <li className={styles.item}>
                <Link href={href} className={styles.link}>
                    <span className={styles.label}>{label}</span>
                </Link>
            </li>
        </>
    );
};
