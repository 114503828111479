import styles           from 'foundations/layout/header.module.css';
import icon             from 'foundations/brand/lockup_dark.svg';
import Image            from 'next/image';
import Link             from 'next/link';
import {HeaderNav}      from 'foundations/layout/header-nav';
import {Button}         from 'foundations/button/button';
import {useTranslation} from 'foundations/i18n/i18n';

export function Header() {
    const {t} = useTranslation();
    return (
        <>
            <header className={styles.header}>
                <div className={`${styles.inner} site-width`}>
                    <div className={`flex items-center justify-between lg:justify-start w-full`}>
                        <Link href={'/'}>
                            <Image className={styles.logo} src={icon} alt={'logo'}/>
                        </Link>
                        <HeaderNav/>
                    </div>
                    <div className={'hidden lg:flex gap-1'}>
                        <Button href={'https://app.withglimpse.com'} variant={'primary'}>{t('Sign In')}</Button>
                    </div>
                </div>
            </header>
        </>
    );
}