import posthog from 'posthog-js';

export class PosthogProvider {

    public static init() {
        if (typeof window !== 'undefined') {
            posthog.init('phc_XvYhD9morNOgpRCxxo4xHhEss5rHZabKXLvPI2ktKaG', {
                api_host:                     'https://app.posthog.com',
                enable_recording_console_log: true
            });
        }
    }
}