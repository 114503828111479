import {useTranslation} from 'foundations/i18n/i18n';
import {Icon}           from '@glimpse/glass';

export function ValuesAccentBand() {
    const {t} = useTranslation();
    return (
        <div className={'bg-slate-900 flex align-middle py-3 gap-6 px-4 justify-evenly max-w-full border-box overflow-x-auto'}>
            <Item color={'text-amber-500'} text={t('Increase Velocity')} icon={'rabbit-running'}/>
            <Item color={'text-emerald-500'} text={t('Control Cost')} icon={'dollar-sign'}/>
            <Item color={'text-sky-500'} text={t('Accelerate onboarding')} icon={'graduation-cap'}/>
            <Item color={'text-yellow-500'} text={t('Promote Quality')} icon={'stars'}/>
            <Item color={'text-purple-500'} text={t('Ensure Standards')} icon={'ballot-check'}/>
            <Item color={'text-rose-500'} text={t('Continuously Improve')} icon={'arrows-rotate'}/>
        </div>
    );
}

const Item = ({
    icon,
    text,
    color
}: {
    color: string,
    text: string,
    icon: string,
}) => {
    return (
        <div className={'nowrap flex-shrink-0'}>
            <Icon fa={['fas', icon]} className={`mr-1.5 ${color}`} fontSize={3}/>
            <span className={'text-white font-medium text-lg nowrap'}>{text}</span>
        </div>
    );
};